<template>
  <v-container class="col-9">
    <v-card>
      <v-card-title>{{ data.fileinfo.name }}</v-card-title>
      <v-card-text>
        Basic file information:
        <ul>
          <li>Filesize: {{ data.fileinfo.size }}</li>
          <li>Filetype: {{ data.fileinfo.extension }}</li>
          <li>MD5 hash: {{ data.fileinfo.hashes.md5 }}</li>
          <li>SHA1 hash: {{ data.fileinfo.hashes.sha1 }}</li>
          <li>SHA256 hash: {{ data.fileinfo.hashes.sha256 }}</li>
        </ul>
        <a
          :href="
            $api_base_url +
            '/get_sample?filename=' +
            data.fileinfo.hashes.sha256.substring(0, 8) +
            '_' +
            data.fileinfo.name
          "
          >Download sample</a
        >
        <v-spacer class="mb-8"></v-spacer>
        <v-tabs v-model="tab">
          <v-tab v-for="title in tab_titles" :key="title">
            {{ title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text v-if="data.fileinfo.extension === 'exe'">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>DOS header</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.dos_header"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Header</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.header"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Optional header</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.optional_header"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Sections</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in data.filedetails.sections"
                        :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Imports</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in data.filedetails.imports"
                        :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>
                          <ul>
                            <li v-for="entry in item.entries" :key="entry">
                              {{ entry }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Exports</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ data.filedetails.exports.name }}</td>
                        <td>
                          <ul>
                            <li
                              v-for="entry in data.filedetails.exports.entries"
                              :key="entry"
                            >
                              {{ entry }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-text v-if="data.fileinfo.extension === 'pdf'">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Metadata</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.metadata"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Objects</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.objects"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>
                          <ul>
                            <li v-for="(data, id) in value" :key="id">
                              {{ id }}: {{ data }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-text
                v-if="['doc', 'xls', 'ppt'].includes(data.fileinfo.extension)"
              >
                <h3 class="py-3">Metadata</h3>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Summary information</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.metadata
                          .SummaryInformation"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Document summary information</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in data.filedetails.metadata
                          .DocumentSummaryInformation"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <h3 class="py-3">Macros</h3>
                <v-list>
                  <v-list-group
                    v-for="macro in data.filedetails.macros"
                    :key="macro"
                    no-action
                    ><template v-slot:activator
                      ><v-list-item-content
                        ><v-list-item-title>{{
                          macro.name
                        }}</v-list-item-title></v-list-item-content
                      ></template
                    >
                    <v-list-item class="pl-5"
                      ><v-list-item-content>
                        <pre>{{ macro.code }}</pre>
                      </v-list-item-content></v-list-item
                    >
                  </v-list-group>
                </v-list>
              </v-card-text>
              <v-card-text v-if="data.filedetails === 'unsupported_filetype'">
                Unsupported filetype
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text v-if="data.virustotal === 'error'">
                Virustotal error
              </v-card-text>
              <v-card-text v-else>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Antivirus engine</th>
                        <th class="text-left">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in data.virustotal"
                        :key="item.engine_name"
                      >
                        <td>{{ item.engine_name }}</td>
                        <td>
                          {{
                            item.result == null ? item.category : item.result
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text
                ><v-simple-table dense
                  ><tbody>
                    <tr v-for="item in data.strings" :key="item">
                      <td>{{ item }}</td>
                    </tr>
                  </tbody></v-simple-table
                ></v-card-text
              >
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text
                ><v-list dense>
                  <v-list-group
                    v-for="item in data.yara"
                    :key="item.rule"
                    no-action
                    ><template v-slot:activator
                      ><v-list-item-content
                        ><v-list-item-title>{{
                          item.rule
                        }}</v-list-item-title></v-list-item-content
                      ></template
                    >
                    <v-list-group no-action sub-group
                      ><template v-slot:activator
                        ><v-list-item-content
                          ><v-list-item-title
                            >Tags</v-list-item-title
                          ></v-list-item-content
                        ></template
                      >
                      <v-list-item v-for="tag in item.tags" :key="tag"
                        ><v-list-item-content>{{
                          tag
                        }}</v-list-item-content></v-list-item
                      >
                    </v-list-group>
                    <v-list-group no-action sub-group
                      ><template v-slot:activator
                        ><v-list-item-content
                          ><v-list-item-title
                            >Metadata</v-list-item-title
                          ></v-list-item-content
                        ></template
                      >
                      <v-list-item
                        v-for="(meta_value, meta_key) in item.meta"
                        :key="meta_key"
                        ><v-list-item-content
                          >{{ meta_key }}: {{ meta_value }}</v-list-item-content
                        ></v-list-item
                      >
                    </v-list-group>
                  </v-list-group>
                </v-list></v-card-text
              >
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-textarea
                outlined
                class="mt-8"
                v-model="data.notes"
              ></v-textarea>
              <v-btn color="green" outlined @click="save"
                ><v-icon left>mdi-content-save</v-icon> Save</v-btn
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AnalysisDetails",
  data() {
    return {
      hash: this.$route.params.hash,
      data: null,
      tab: null,
      tab_titles: ["Details", "Virustotal", "Strings", "Yara", "Notes"],
    };
  },
  mounted() {
    axios
      .get(this.$api_base_url + "/analysis/details?hash=" + this.hash)
      .then((response) => (this.data = response.data));
  },
  methods: {
    save() {
      axios.post(this.$api_base_url + "/analysis/update", {
        hash: this.hash,
        notes: this.data.notes,
      });
    },
  },
};
</script>